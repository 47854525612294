import React, { useCallback, useMemo, useContext, forwardRef } from 'react'
import { connect } from 'react-redux'

import TripGuard from '../../TripGuard/TripGuard'
import BookmarkCard from '../BookmarkCardNew/BookmarkCard'
import BookmarkLocation from '../BookmarkCardNew/BookmarkLocation'

import { accountAccessShowLogin } from 'actions/UiActions'
import SecureWithDepositTagAndModal from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndModal'
import WalledContent from 'components/Common/WalledContent'
import TextLink from 'components/Luxkit/TextLink'
import { getDepositPropertiesForOffer } from 'components/OfferList/OfferListTiles/YouMayAlsoLikeTile/Common/createYouMayAlsoLikeTileView'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
} from 'constants/offer'
import ClickTrackerContext from 'contexts/ClickTrackerContext'
import GeoContext from 'contexts/geoContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import useImpressionHandler from 'hooks/useImpressionHandler'
import noop from 'lib/function/noop'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import { getImageUrl } from 'lib/image/imageUtils'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import offerPageURL from 'lib/offer/offerPageURL'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'
import { getDefaultDepositAmountPercentage } from 'tripPlanner/../checkout/selectors/featureConfig/deposit'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/BookmarkButton'
import LEOfferPriceDetails from 'tripPlanner/components/ItemOfferPrice/LEOfferPriceDetails'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface Props {
  currency: string
  distance?: number | null
  distanceFrom?: string | null
  distanceUnit?: DistanceUnit
  offer: App.Offer | App.OfferSummary
  linkQuery?: URLSearchParams
  onClick?: (offer: App.Offer | App.OfferSummary) => void
  recommendation?: App.Recommendation
  duration?: number
  className?: string
  defaultDepositAmountPercentage: number
  aspectRatio?: string
  source?: string
  target?: '_blank'
  shouldProcessOfferUrgencyLabel?: boolean
  position?: number
}

const validOfferTypes = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_LAST_MINUTE,
]

const LEOfferCard = forwardRef<HTMLAnchorElement, Props>(function LEOfferCard(
  {
    currency,
    distance,
    distanceFrom,
    distanceUnit,
    offer,
    linkQuery,
    onClick = noop,
    recommendation,
    duration,
    className,
    defaultDepositAmountPercentage,
    aspectRatio,
    source,
    target,
    shouldProcessOfferUrgencyLabel,
    position,
  }: Props,
  ref,
) {
  const dispatch = useAppDispatch()
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const offerUrl = addQueryParamsToPath(offerPageURL(offer, linkQuery), {
    source,
  })
  const { currentRegionCode } = useContext(GeoContext)
  const urgencyLabels = useMemo(
    () =>
      shouldProcessOfferUrgencyLabel ?
        getUrgencyLabels(offer, currentRegionCode) :
        offer.urgencyTags,
    [shouldProcessOfferUrgencyLabel, offer, currentRegionCode],
  )

  const impressionHandlerRef = useImpressionHandler(offer.id)

  const clickTracker = useContext(ClickTrackerContext)

  const onCardClick = useCallback(() => {
    clickTracker(offer.id)
    onClick(offer)
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position,
      })
    }
  }, [clickTracker, dispatchOfferListEvent, offer, onClick, position])

  if (!validOfferTypes.includes(offer.type)) {
    return null
  }

  const { image, property } = offer
  const title = property?.name
  const location = [offer.locationHeading, offer.locationSubheading]
    .filter((t) => t)
    .join(', ')
  const imageUrl =
    image.url ??
    (image.id &&
      getImageUrl(image.id, aspectRatio ? { aspectRatio } : { width: '320px' }))
  const offerAvailable = scheduleIsCurrent(offer.onlinePurchaseSchedule)
  const depositProps = getDepositPropertiesForOffer(
    offer,
    currency,
    defaultDepositAmountPercentage,
  )

  const depositComponent = depositProps.depositShown ? (
    <SecureWithDepositTagAndModal
      depositAmountPercentage={depositProps.depositAmountPercentage}
      depositThresholds={depositProps.depositThresholds}
      offerType={offer.type}
    />
  ) : undefined

  const wall = (
    <TextLink
      onClick={(e) => {
        e.preventDefault()
        dispatch(accountAccessShowLogin('tripPlannerLogin'))
      }}
    >
      Become a member to see rates
    </TextLink>
  )

  return (
    <BookmarkCard
      ref={(element) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(element)
          } else {
            ref.current = element
          }
        }
        impressionHandlerRef(element)
      }}
      className={className}
      title={title}
      body={offer.name}
      bookmarkButton={
        <TripGuard>
          <BookmarkButton offer={offer} />
        </TripGuard>
      }
      location={<BookmarkLocation startLocation={location} />}
      distance={distance}
      distanceFrom={distanceFrom}
      distanceUnit={distanceUnit}
      imageUrl={imageUrl}
      productType={offer.productType}
      offerPriceDetails={
        <WalledContent enforced={offer.walledGarden} wall={wall}>
          <LEOfferPriceDetails
            depositComponent={depositComponent}
            offer={offer}
            recommendation={recommendation}
            duration={duration}
          />
        </WalledContent>
      }
      offerUrl={offerUrl}
      onClick={onCardClick}
      urgencyLabels={urgencyLabels}
      isSoldOut={!offerAvailable}
      soldOutOfferId={offer.id}
      target={target}
    />
  )
})

function mapState(state: App.State) {
  return {
    currency: state.geo.currentCurrency,
    defaultDepositAmountPercentage: getDefaultDepositAmountPercentage(state),
  }
}

export default connect(mapState, null, null, { forwardRef: true })(LEOfferCard)
