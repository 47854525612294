import React from 'react'
import { shallowEqual } from 'react-redux'
import styled from 'styled-components'

import OnlyIfChildren from 'components/Common/OnlyIfChildren'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import PriceRowLuxPlusNonMemberPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusNonMemberPrice'
import PriceRowLuxPlusPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPrice'
import PriceRowLuxPlusPriceBox from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPriceBox'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import NoPrint from 'components/utils/NoPrint'
import { CurrencyProvider } from 'contexts/currencyContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { getPlural } from 'lib/string/pluralize'
import {
  checkCanViewLuxPlusBenefits,
  isLuxPlusEnabled,
} from 'luxPlus/selectors/featureToggle'
import PriceRowValueDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscount'

export type DiscountDisplayType = 'badge' | 'inlineBadge' | 'following' | 'none'

export type Props = {
  depositComponent?: React.ReactNode
  discountDisplayType?: DiscountDisplayType
  discount?: number
  price: number
  luxPlusPrice?: number
  value?: number
  currencyCode: string
  priceLabel: string
  totalLabel?: string
  roomOccupancy?: string
  className?: string
}

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RoomText = styled.div`
  font-size: 11px;
  font-weight: ${(props) => props.theme.font.primary.weight.regular};
  line-height: 16px;
  color: ${(props) => props.theme.palette.neutral.default.two};
`

const buildPriceLabel = (min: number, max: number, singular: string) => {
  const nights = min !== max ? `${min} to ${max}` : `${min}`
  const qualifier = getPlural(singular, min)
  return `${nights} ${qualifier} from`
}

export const buildNightsPriceLabel = (nightsMin: number, nightsMax: number) =>
  buildPriceLabel(nightsMin, nightsMax, 'night')

export const buildDaysPriceLabel = (daysMin: number, daysMax: number) =>
  buildPriceLabel(daysMin, daysMax, 'day')

function PriceDetails({
  depositComponent,
  discountDisplayType = 'following',
  discount,
  price,
  luxPlusPrice,
  value,
  currencyCode,
  priceLabel,
  totalLabel,
  roomOccupancy,
  className,
}: Props) {
  const [luxPlusEnabled, canViewLuxPlusBenefits] = useAppSelector(
    (state) => [isLuxPlusEnabled(state), checkCanViewLuxPlusBenefits(state)],
    shallowEqual,
  )
  const showLuxPlusPricing =
  luxPlusEnabled && !!luxPlusPrice && luxPlusPrice < price

  return (
    <CurrencyProvider currency={currencyCode}>
      <PriceWrapper className={className}>
        <VerticalSpacer gap={4}>
          <OnlyIfChildren as={VerticalSpacer} gap={8}>
            {discountDisplayType === 'badge' && discount && (
              <NoPrint>
                <PriceRowValueDiscount
                  type="estimate"
                  discountPercentage={discount}
                />
              </NoPrint>
            )}
            {depositComponent && <span>{depositComponent}</span>}
          </OnlyIfChildren>
          <PriceRowPriceCaption>{priceLabel}</PriceRowPriceCaption>
        </VerticalSpacer>
        {!showLuxPlusPricing && (
          <PriceRowPrice
            size="M"
            price={price}
            saleUnit={totalLabel ?? 'total'}
            discountPercentage={
              discountDisplayType === 'inlineBadge' ? discount : undefined
            }
            discountType="estimate"
          />
        )}
        {showLuxPlusPricing && canViewLuxPlusBenefits && (
          <VerticalSpacer gap={4}>
            <PriceRowLuxPlusPriceBox
              size="S"
              memberPrice={luxPlusPrice}
              saleUnit={totalLabel ?? 'total'}
            />
            <PriceRowLuxPlusNonMemberPrice size="M" nonMemberPrice={price} />
          </VerticalSpacer>
        )}
        {showLuxPlusPricing && !canViewLuxPlusBenefits && (
          <>
            <PriceRowPrice
              size="S"
              price={price}
              saleUnit={totalLabel ?? 'total'}
              discountPercentage={
                discountDisplayType === 'inlineBadge' ? discount : undefined
              }
              discountType="estimate"
            />
            <PriceRowLuxPlusPrice
              size="S"
              memberPrice={luxPlusPrice}
              saleUnit={totalLabel ?? 'total'}
              logoPlacement="trailing"
            />
          </>
        )}
        {discountDisplayType === 'following' && !!value && !!discount && (
          <NoPrint>
            <PriceRowValueDiscountWithCaption
              size="L"
              originalValue={Math.ceil(value)}
              discountPercentage={discount}
            />
          </NoPrint>
        )}
        {roomOccupancy && <RoomText>{roomOccupancy}</RoomText>}
        <PriceRowTaxesAndFees />
      </PriceWrapper>
    </CurrencyProvider>
  )
}
export default PriceDetails
