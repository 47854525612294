import React, { useContext } from 'react'

import PriceDetails, {
  DiscountDisplayType,
} from './PriceDetails'

import BodyText from 'components/Luxkit/Typography/BodyText'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import GeoContext from 'contexts/geoContext'
import useDefaultAirport from 'hooks/useDefaultAirport'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import {
  extractPriceAndValue,
  getDurationLabel,
} from 'tripPlanner/utils/bookmarkCard'

const discountDisplayTypeMapping: Utils.PartialRecord<
  App.OfferType,
  DiscountDisplayType
> = {
  hotel: 'badge',
  tactical_ao_hotel: 'badge',
  last_minute_hotel: 'following',
  bundle_and_save: 'none',
  rental: 'none',
}

interface Props {
  offer: App.Offer | App.OfferSummary
  package?: App.Package
  depositComponent?: JSX.Element
  className?: string
  recommendation?: App.Recommendation
  duration?: number
}

function LEOfferPriceDetails({
  depositComponent,
  offer,
  package: pkg = offer.lowestPricePackage,
  className,
  recommendation,
  duration = -1,
}: Props) {
  const defaultAirport = useDefaultAirport()
  const flightPrice = offer.flightPrices[defaultAirport?.code ?? '']

  const { currentCurrency } = useContext(GeoContext)

  if (!pkg) {
    return null // TODO handle this
  }

  const { durationLabel } = pkg

  let { price, luxPlusPrice, value } = extractPriceAndValue({
    recommendation,
    pkg,
  })

  let priceLabel = getDurationLabel({
    durationLabel,
    duration,
    recommendation,
  })

  if (offer.bundledWithFlightsOnly) {
    if (typeof flightPrice !== 'number') {
      // If `bundledWithFlightsOnly` is true, we *have* to show the price with flights for contractual reasons.
      // So if we don't have the flight price for whatever reason, then we just can't show the price here.
      return (
        <BodyText variant="small" weight="normal">
          {offer.durationLabel.toLocaleLowerCase()} + flights
        </BodyText>
      )
    }

    priceLabel = `${durationLabel.toLocaleLowerCase()} + flights from`
    price += flightPrice
    if (luxPlusPrice) {
      luxPlusPrice += flightPrice
    }
    value += flightPrice
  }

  let discount: number | undefined = getValueOffPercent(value, price)
  if (discount < MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE) {
    discount = undefined
  }

  return (
    <PriceDetails
      currencyCode={currentCurrency}
      price={price}
      luxPlusPrice={luxPlusPrice}
      value={value}
      discount={discount}
      priceLabel={priceLabel}
      depositComponent={depositComponent}
      discountDisplayType={discountDisplayTypeMapping[offer.type] ?? 'none'}
      className={className}
    />
  )
}

export default LEOfferPriceDetails
