import noop from 'lib/function/noop'
import React from 'react'

type ImpressionHandler = (id: string) => void;

// OfferListImpressionTrackerContext provides an interface to track the impression
// of offers in an offer list (or anything that has an ID).
const ImpressionTrackerContext = React.createContext<ImpressionHandler>(noop)

export default ImpressionTrackerContext
