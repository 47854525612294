import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { cardItemHoverAnimation } from 'tripPlanner/utils/css'

const Container = styled.div`
  width: 260px;

  ${mediaQueryUp.tablet} {
    width: 280px;
  }

  background: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${(props) => props.theme.borderRadius.M};

  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  align-items: center;
  place-content: center;
  overflow: hidden;

  ${cardItemHoverAnimation}
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  gap: ${rem(8)};
  align-items: center;
`

export default function CarouselCTACard({ children }: PropsWithChildren) {
  return (
    <Container>
      <BodyText variant="small" weight="bold">
        Not quite what you're looking for?
      </BodyText>
      <Wrapper>{children}</Wrapper>
    </Container>
  )
}
