import React, { useCallback, useContext } from 'react'

import GeoContext from 'contexts/geoContext'
import { EmptyObject } from 'lib/object/objectUtils'
import {
  isBedbankOffer,
  isLEOffer,
  isTourOffer,
  isTourV2Offer,
} from 'lib/offer/offerTypes'
import BedbankCard from 'tripPlanner/components/Bookmark/BookmarkCard/BedbankCard'
import LEOfferCard from 'tripPlanner/components/Bookmark/BookmarkCard/LEOfferCard'
import TourBookmarkCard from 'tripPlanner/components/Bookmark/BookmarkCard/TourBookmarkCard'
import TourV2Card from 'tripPlanner/components/Bookmark/BookmarkCard/TourV2Card'
import TripItemCarousel from 'tripPlanner/components/Bookmark/Carousels/TripItemCarousel'
import { useOffersWithCollectionAPIBecauseToursV2AreNotReturnedFromTheOtherOne } from 'tripPlanner/hooks/api'
import useImmersiveModeHandlers from 'tripPlanner/hooks/useImmersiveModeHandlers'
import useTripClientSource from 'tripPlanner/hooks/useTripClientSource'

type Props = {
  offerIds: Array<string>
  ctaCard?: JSX.Element
  tripId: string
  sectionId: string
  recommendationMap?: Record<string, App.Recommendation>
  duration?: number
  linkQuery?: URLSearchParams
  parentLoading?: boolean
}

const aspectRatio = '2:1'

function OfferItemCarousel(props: Props) {
  const {
    offerIds,
    tripId,
    ctaCard,
    recommendationMap = EmptyObject,
    duration,
    linkQuery,
    parentLoading = false,
    sectionId,
  } = props
  const { setImmersiveMode } = useImmersiveModeHandlers()

  const onClick = useCallback(() => {
    setImmersiveMode({
      sectionId,
      tripId,
    })
  }, [tripId, sectionId, setImmersiveMode])

  const { currentRegionCode } = useContext(GeoContext)
  const { data: offers, isLoading: isOffersLoading } =
    useOffersWithCollectionAPIBecauseToursV2AreNotReturnedFromTheOtherOne(
      offerIds,
      currentRegionCode,
    )
  const source = useTripClientSource()

  return (
    <TripItemCarousel isLoading={isOffersLoading || parentLoading}>
      {/* Carousel has issues rendering null items */}
      {[
        ...(offers ?? []).map((offer) => {
          if (isBedbankOffer(offer)) {
            return (
              <BedbankCard
                key={offer.id}
                offer={offer}
                onClick={onClick}
                aspectRatio={aspectRatio}
                source={source}
              />
            )
          } else if (isTourV2Offer(offer)) {
            return (
              <TourV2Card
                key={offer.id}
                offer={offer}
                onClick={onClick}
                aspectRatio={aspectRatio}
                source={source}
              />
            )
          } else if (isLEOffer(offer)) {
            if (isTourOffer(offer)) {
              return (
                <TourBookmarkCard
                  key={offer.id}
                  offer={offer}
                  onClick={onClick}
                  aspectRatio={aspectRatio}
                  source={source}
                />
              )
            }
            return (
              <LEOfferCard
                key={offer.id}
                offer={offer}
                onClick={onClick}
                recommendation={recommendationMap[offer.id]}
                duration={duration}
                linkQuery={linkQuery}
                aspectRatio={aspectRatio}
                source={source}
              />
            )
          }
        }),
        offers && offers.length > 0 ? ctaCard : null,
      ].filter(Boolean)}
    </TripItemCarousel>
  )
}

export default OfferItemCarousel
