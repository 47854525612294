import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import BodyText from 'components/Luxkit/Typography/BodyText'

const LineWithGaps = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(4)};
`

type TourV2DurationTextProps = {
  durationInDaysCount: string
  countriesCount?: string
  placesCount?: string
}
function TourV2DurationText({
  countriesCount,
  durationInDaysCount,
  placesCount,
}: TourV2DurationTextProps) {
  return (
    <LineWithGaps>
      <BodyText weight="bold" variant="small">
        {durationInDaysCount}
      </BodyText>
      <BodyText weight="normal" variant="small">
        ·
      </BodyText>
      <BodyText weight="bold" variant="small">
        {placesCount}
      </BodyText>
      <BodyText weight="normal" variant="small">
        ·
      </BodyText>
      <BodyText weight="bold" variant="small">
        {countriesCount}
      </BodyText>
    </LineWithGaps>
  )
}
export default TourV2DurationText
