import noop from 'lib/function/noop'
import React from 'react'

type ClickHandler = (id: string) => void;

// OfferListImpressionTrackerContext provides an interface to track clicks
// on offers in an offer list (or anything that has an ID).
const ClickTrackerContext = React.createContext<ClickHandler>(noop)

export default ClickTrackerContext
