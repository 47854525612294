import {
  OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2_ANALYTICS,
} from 'constants/offer'
import offerPageURL from 'lib/offer/offerPageURL'
import { findCheapestOfTourV2Offer } from 'lib/tours/tourUtils'
import { max } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { getDepositAmountPercentage, isDepositAvailableOnOffer, isDepositEnabledForOfferType } from 'lib/payment/depositsUtils'
import isTourV2DepositAllowed from 'lib/tours/isTourV2DepositAllowed'
import { TrackingProps } from 'contexts/trackingContext'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import { YmalPricingProperties } from '../Common/types'
import { getOfferName } from 'lib/offer/offerUtils'
import { getTimeLeftUrgencyLabels } from 'lib/offer/highIntentOffers'

interface BaseProps {
  distance?: number
  distanceFrom?: string
  distanceUnit?: DistanceUnit
  currency: string
  linkQuery?: URLSearchParams
  position: number
  testId: string
  tracking?: TrackingProps
  defaultDepositAmountPercentage: number
}

interface TourV2Props extends Omit<BaseProps, 'distanceUnit'> {
  offer: Tours.TourV2Offer
}

interface OfferProps extends BaseProps {
  offer: App.Offer | App.OfferSummary;
  currentRegionCode?: string;
  offerAvailableRates?: App.OfferAvailableRates;
  countdownLabelVariant?: string;
}

interface BedbankProps extends BaseProps {
  offer: App.BedbankOffer
}

export function createYouMayAlsoLikeTileViewForBedbank({
  currency,
  linkQuery,
  offer,
  testId,
  position,
  tracking,
  distance,
  distanceFrom,
  distanceUnit,
}: BedbankProps) {
  const { id, image, property, type } = offer
  const pricing = getPricingPropertiesForBedbankOffer(offer)
  const addressArray: Array<string> = []
  const { city, countryName } = property.address
  if (city) addressArray.push(city)
  if (countryName) addressArray.push(countryName)
  const location = addressArray.join(', ')
  const sell = offer.sell

  return {
    pricing,
    showHotelPrice: !!sell?.price,
    shouldDisplayValue: !!sell?.value,
    value: sell?.value,
    category: offer.analyticsType,
    currency,
    depositShouldDisplay: false,
    distance,
    distanceFrom,
    distanceUnit,
    image: {
      id: image.id,
      title: image.title,
    },
    location,
    name: getOfferName(offer)!,
    offerType: type,
    offerId: id,
    position,
    tracking,
    testId,
    title: property.name,
    url: offerPageURL(offer, linkQuery),
    urgencyLabels: [],
    rating: property.rating,
  }
}

export function getDepositPropertiesForTourV2(offer: Tours.TourV2OfferSummary | Tours.TourV2Offer, currency: string) {
  const { cheapestPurchasableOption } = findCheapestOfTourV2Offer(offer, undefined)

  if (!cheapestPurchasableOption || !offer.depositType) {
    return {
      depositType: undefined,
      depositAmount: undefined,
      depositAmountPercentage: undefined,
      depositShouldDisplay: false,
    }
  }

  const pricePerPerson = cheapestPurchasableOption.price

  const cheapestDeparture = offer.departures[cheapestPurchasableOption.fkDepartureId]
  const depositShouldDisplay = isTourV2DepositAllowed(cheapestDeparture.startDate, pricePerPerson, currency, offer.depositType, offer.depositThresholds?.numberOfDays)

  return {
    depositType: offer.depositType,
    depositAmount: offer.depositAmount ? offer.depositAmount : undefined,
    depositAmountPercentage: undefined,
    depositShouldDisplay,
  }
}

export function createYouMayAlsoLikeTileViewForToursV2({
  currency,
  linkQuery,
  offer,
  testId,
  position,
  tracking,
}: TourV2Props) {
  const { id, name, type } = offer
  const { cheapestVariation } = findCheapestOfTourV2Offer(offer)
  const variation = cheapestVariation ?? Object.values(offer.variations)[0]
  const image = variation.images[0]
  const startLocation = variation.startLocation
  const endLocation = variation.endLocation
  const hasTheSameStartAndEndLocation = variation.startLocation === variation.endLocation
  const location = hasTheSameStartAndEndLocation ?
    `Starts and ends in ${endLocation}` : `Starts in ${startLocation}, Ends in ${endLocation}`

  const depositProps = getDepositPropertiesForTourV2(offer, currency)

  return {
    category: OFFER_TYPE_TOUR_V2_ANALYTICS,
    currency,
    depositType: depositProps.depositType,
    depositAmount: depositProps.depositAmount,
    depositAmountPercentage: depositProps.depositAmountPercentage,
    depositShouldDisplay: depositProps.depositShouldDisplay,
    image: {
      id: image.id,
      title: image.title,
    },
    location,
    name: getOfferName(offer)!,
    offerType: type,
    offerId: id,
    position,
    pricing: getPricingPropertiesForTourV2(offer),
    tracking,
    testId,
    title: name,
    url: offerPageURL(offer, linkQuery),
    urgencyLabels: [],
  }
}

export function getDepositPropertiesForOffer(
  offer: App.Offer | App.OfferSummary,
  currency: string,
  defaultDepositAmountPercentage: number,
  offerAvailableRates?: App.OfferAvailableRates,
) {
  const depositShown = (() => {
    if (!isDepositEnabledForOfferType(offer.type)) {
      return false
    }
    if (offer.type === OFFER_TYPE_ALWAYS_ON) {
      const pkg = offer.lowestPricePackage
      const pkgAvailableRate = offerAvailableRates?.rates?.find(rate => rate.packageUniqueKey === pkg.uniqueKey)
      return isDepositAvailableOnOffer(offer, currency, null, pkg.price, pkg, pkgAvailableRate)
    }
    return isDepositAvailableOnOffer(offer, currency, null, offer.lowestPricePackage.price)
  })()
  const depositAmountPercentage = depositShown ? getDepositAmountPercentage(offer, defaultDepositAmountPercentage) : undefined
  return {
    depositShown,
    depositAmountPercentage,
    depositThresholds: offer.depositThresholds,
  }
}

export function createYouMayAlsoLikeTileViewForOffers({
  currency,
  linkQuery,
  offer,
  position,
  testId,
  tracking,
  offerAvailableRates,
  distance,
  distanceFrom,
  distanceUnit,
  defaultDepositAmountPercentage,
  currentRegionCode,
  countdownLabelVariant,
}: OfferProps) {
  const {
    analyticsType,
    flightPrices,
    id,
    image,
    bundledWithFlightsOnly,
    locationHeading,
    locationSubheading,
    lowestPricePackage: { shouldDisplayValue, value },
    name,
    property,
    type,
    walledGarden,
  } = offer

  const depositProperties = getDepositPropertiesForOffer(offer, currency, defaultDepositAmountPercentage, offerAvailableRates)

  // we get the 'X H left' label
  const urgencyLabels = getTimeLeftUrgencyLabels(offer, currentRegionCode, countdownLabelVariant)

  return {
    category: analyticsType,
    currency,
    depositAmountPercentage: depositProperties.depositAmountPercentage,
    depositThresholds: depositProperties.depositThresholds,
    depositShouldDisplay: depositProperties.depositShown,
    distance,
    distanceFrom,
    distanceUnit,
    flights: {
      bundledWithFlightsOnly,
      flightPrices,
    },
    image: {
      id: image.id,
      title: image.title,
    },
    location: [locationHeading, locationSubheading].filter(t => t).join(', '),
    offerType: type,
    pricing: getPricingPropertiesForOffer(offer),
    name: getOfferName(offer)!,
    offerId: id,
    position,
    shouldDisplayValue,
    testId,
    title: property?.name ?? name,
    tracking,
    url: offerPageURL(offer, linkQuery),
    value,
    walledGarden,
    urgencyLabels,
    rating: property?.rating,
  }
}

export function getPricingPropertiesForTourV2(offer: Tours.TourV2Offer): YmalPricingProperties | undefined {
  const { cheapestPurchasableOption, cheapestVariation } = findCheapestOfTourV2Offer(offer, undefined)

  if (!cheapestPurchasableOption || !cheapestVariation) { return }

  const lastDay = max(cheapestVariation.itinerary, (item) => item.startDay)!
  const daysCount = lastDay.startDay + lastDay.duration - 1
  return {
    duration: pluralizeToString('day', daysCount),
    price: cheapestPurchasableOption.price,
    // member price is not available for tours v2
    memberPrice: 0,
    saleUnit: 'person',
  }
}

export function getPricingPropertiesForOffer(offer: App.Offer | App.OfferSummary): YmalPricingProperties | undefined {
  const {
    saleUnit,
    type,
    lowestPricePackage,
  } = offer

  if (!lowestPricePackage) return

  const singular = type === OFFER_TYPE_TOUR ? 'day' : 'night'
  return {
    duration: pluralizeToString(singular, lowestPricePackage.duration),
    hotelNights: lowestPricePackage.duration,
    price: lowestPricePackage.price,
    memberPrice: lowestPricePackage.memberPrice,
    saleUnit,
  }
}

export function getPricingPropertiesForBedbankOffer(offer: App.BedbankOfferSummary): YmalPricingProperties | undefined {
  if (!offer.sell) return

  const pricing = {
    duration: pluralizeToString('night', offer.sell.los),
    hotelNights: offer.sell.los,
    saleUnit: 'night',
    price: offer.sell.price,
    // member price is not available for bedbank offers
    memberPrice: 0,
  }

  return pricing
}
