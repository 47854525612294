import { Moment } from 'moment'
import React, { ComponentProps, useContext } from 'react'

import TextButton from 'components/Luxkit/Button/TextButton'
import ModalContext from 'contexts/ModalContext'
import MoreOffersModal from 'tripPlanner/components/TripModal/ViewMoreOffers/MoreOffersModal'

interface Props {
  offerId?: string
  checkIn?: Moment
  checkOut?: Moment
  tripId: string
  sectionId: string
  className?: string
  showSearch: boolean
  size?: ComponentProps<typeof TextButton>['size']
}

function ViewMoreOffersCta({
  offerId,
  checkIn,
  checkOut,
  sectionId,
  tripId,
  className,
  showSearch,
  size = 'medium',
}: Props) {
  const showModal = useContext(ModalContext)

  return (
    <TextButton
      kind="secondary"
      size={size}
      className={className}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        showModal(
          <MoreOffersModal
            tripId={tripId}
            sectionId={sectionId}
            offerId={offerId}
            checkIn={checkIn}
            checkOut={checkOut}
            showSearch={showSearch}
          />,
        )
      }}
    >
      View more offers
    </TextButton>
  )
}

export default ViewMoreOffersCta
