import React from 'react'

import TextButton from 'components/Luxkit/Button/TextButton'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import noop from 'lib/function/noop'
import SearchAccommodation from 'tripPlanner/components/TripModal/TripItems/Search/SearchAccommodation'
import useOpenModal from 'tripPlanner/hooks/View/useOpenModal'

type Props = {
  tripId: string
  sectionId?: string
  onClick?: () => void
}

function HotelSearchModalCTA({ tripId, sectionId, onClick = noop }: Props) {
  const openModal = useOpenModal()
  return (
    <TextButton
      kind="primary"
      startIcon={<LineSearchIcon />}
      onClick={() => {
        onClick()
        openModal(SearchAccommodation, {
          tripId,
          sectionId,
        })
      }}
    >
      Search hotels
    </TextButton>
  )
}

export default HotelSearchModalCTA
