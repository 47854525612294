import React from 'react'
import styled, { PolymorphicComponentProps } from 'styled-components'
import { StyledTarget } from 'styled-components/dist/types'

const Root = styled.div`
  @media print {
    display: none;
  }
  &:empty {
    display: none;
  }
`

const NoPrint = React.forwardRef(function NoPrintInner<AsTarget extends StyledTarget<'web'> = 'div'>(
  props: PolymorphicComponentProps<'web', {}, AsTarget, void>,
  ref: any,
) {
  const {
    as: Component = 'div',
    children,
    ...restProps
  } = props

  // The `any` here isn't ideal, but the dynamic prop typing makes things tricky
  return <Root as={Component} {...restProps as any} ref={ref}>{children}</Root>
})

export default NoPrint
