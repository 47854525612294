import React from 'react'

import BaseBookmarkButton from 'tripPlanner/components/Bookmark/Common/BaseBookmarkButton'

export type SimpleBookmarkButtonProps = {
  isSaved: boolean
  onClick: (() => void) | undefined
  label: string
}

function SimpleBookmarkButton({
  isSaved,
  onClick,
  label,
}: SimpleBookmarkButtonProps) {
  return (
    <BaseBookmarkButton
      onClick={onClick}
      aria-label={label}
      isSaved={isSaved}
      label={label}
    />
  )
}

export default SimpleBookmarkButton
