import React from 'react'

import Modal from 'components/Luxkit/Modal/Modal'

import { depositAmountStringBuilder } from 'lib/tours/tourUtils'
import DepositInfoModalContent, { Props as DepositInfoModalContentProps } from './DepositInfoModalContent'

import { getDefaultDepositAmountPercentage } from 'checkout/selectors/featureConfig/deposit'
import { isOfferDefaultDepositAmountPercentage } from 'lib/payment/depositsUtils'
import { useAppSelector } from 'hooks/reduxHooks'

interface Props extends DepositInfoModalContentProps {
  isModalOpen?: boolean;
  onCloseModal?: () => void;
}

export function DepositInfoModal({ isModalOpen, onCloseModal, ...rest }: Props) {
  const defaultDepositAmountPercentage = useAppSelector(getDefaultDepositAmountPercentage)

  const isOfferUsingDefaultDepositPercentage = isOfferDefaultDepositAmountPercentage(rest.depositThresholds, rest.depositAmount)

  const standardModalHeading = isOfferUsingDefaultDepositPercentage ? 'Pay a deposit' : rest.depositType ?
    `Pay ${depositAmountStringBuilder(defaultDepositAmountPercentage, rest.depositType, rest.depositAmountPercentage).short} deposit` :
    `Pay ${rest.depositAmountPercentage}% deposit`

  return (
    <Modal
      title={standardModalHeading}
      isOpen={isModalOpen}
      onClose={onCloseModal}
    >
      <DepositInfoModalContent {...rest}/>
    </Modal>
  )
}
