import { css } from 'styled-components'

import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'

export const cardItemHoverAnimation = css`
  transition: box-shadow 0.2s;
  box-shadow: none;

  &:focus,
  &:focus-within,
  &:hover {
    box-shadow: ${(props) => props.theme.shadow.bottom.small};
  }

  &:focus,
  &:focus-within {
    outline: var(
      ${KEYBOARD_MODE_CSS_VAR},
      2px solid ${(props) => props.theme.palette.neutral.default.five}
    );
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

// Not really sure what else to add here, comment in PR
export const buttonFakerStyles = css`
  &[role='button'] {
    [aria-disabled] {
      cursor: not-allowed;
    }
    cursor: pointer;
  }
`
