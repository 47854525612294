import { useContext, useEffect, useRef } from 'react'
import ImpressionTrackerContext from 'contexts/ImpressionTrackerContext'
import { useInView } from 'react-intersection-observer'

const defaultInViewOptions: Parameters<typeof useInView>[0] = {
  triggerOnce: true,
}

function useImpressionHandler(id: string, inViewOptions?: Parameters<typeof useInView>[0]) {
  const [containerRef, seen] = useInView({
    ...defaultInViewOptions,
    ...inViewOptions,
  })
  const onImpression = useContext(ImpressionTrackerContext)

  // Ref makes sure the effect always has access to the current value of impressionParams,
  // without having to list it as a dependency
  const idRef = useRef(id)
  idRef.current = id

  useEffect(() => {
    if (seen) {
      onImpression(idRef.current)
    }
  }, [seen, onImpression])

  return containerRef
}

export default useImpressionHandler
