import { rem } from 'polished'
import styled from 'styled-components'

export const MessageContainer = styled.div`
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${rem(16)};
`
