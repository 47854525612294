import React from 'react'

import TextButton from 'components/Luxkit/Button/TextButton'
import noop from 'lib/function/noop'
import CreateTripItem from 'tripPlanner/components/TripModal/TripItems/Create'
import useOpenModal from 'tripPlanner/hooks/View/useOpenModal'

interface Props {
  tripId: string
  onClick?: () => void
}

function AddCustomHotelCTA({ tripId, onClick = noop }: Props) {
  const openModal = useOpenModal()

  return (
    <TextButton
      kind="secondary"
      onClick={() => {
        onClick()
        openModal(CreateTripItem, {
          itemType: 'ACCOMMODATION',
          tripId,
        })
      }}
      data-testid="add-custom-hotel-cta"
    >
      Add my own
    </TextButton>
  )
}

export default AddCustomHotelCTA
