import React from 'react'

export const SectionIdContext = React.createContext<string>('')

interface Props {
  sectionId: string
  children: React.ReactNode
}

function SectionIdProvider({ children, sectionId }: Props) {
  return (
    <SectionIdContext.Provider value={sectionId}>
      {children}
    </SectionIdContext.Provider>
  )
}

export default SectionIdProvider
