import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import CaptionTextBlock from 'components/Luxkit/TextBlocks/CaptionTextBlock'
import Caption from 'components/Luxkit/Typography/Caption'

const Location = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(4)};
  align-items: center;
`

const LittleMarker = styled(LineMapMarkerIcon)`
  height: ${rem(20)};
  width: ${rem(20)};
`

export type BookmarkLocationProps = {
  startLocation?: string | null
  endLocation?: string | null
  typeOfExperience?: string
  variant?: 'single' | 'start_and_end' | 'with_experience'
  showIcon?: boolean
  size?: 'small' | 'medium' | 'large'
}

function Single({ startLocation, size = 'large' }: BookmarkLocationProps) {
  return (
    <CaptionTextBlock variant={size} lineClamp={2}>
      {startLocation}
    </CaptionTextBlock>
  )
}

function StartAndEnd({
  startLocation,
  endLocation,
  size = 'large',
}: BookmarkLocationProps) {
  return (
    <CaptionTextBlock variant={size} lineClamp={2}>
      Starts in{' '}
      <Caption as="span" weight="bold" variant={size}>
        {startLocation}
      </Caption>
      , Ends in{' '}
      <Caption as="span" weight="bold" variant={size}>
        {endLocation}
      </Caption>
    </CaptionTextBlock>
  )
}

function WithTypeOfExperience({
  startLocation,
  typeOfExperience,
  size = 'large',
}: BookmarkLocationProps) {
  return (
    <CaptionTextBlock variant={size} lineClamp={2}>
      {startLocation}
      {typeOfExperience && (
        <Caption as="span" variant={size} colour="neutral-two">
          {` · ${typeOfExperience}`}
        </Caption>
      )}
    </CaptionTextBlock>
  )
}

function BookmarkLocation(props: BookmarkLocationProps) {
  const variant = props.variant ?? 'single'
  const showIcon = props.showIcon ?? true
  return (
    <Location>
      {showIcon && <LittleMarker />}
      {variant === 'single' && <Single {...props} />}
      {variant === 'start_and_end' && <StartAndEnd {...props} />}
      {variant === 'with_experience' && <WithTypeOfExperience {...props} />}
    </Location>
  )
}
export default BookmarkLocation
