import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import LoadingBox from 'components/Common/Loading/LoadingBox'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import { fillArray } from 'lib/array/arrayUtils'

const SkeletonTile = styled(LoadingBox)`
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  border-radius: ${(props) => props.theme.borderRadius.M};
  min-height: ${rem(364)};
  width: ${rem(280)};
`

type Props = {
  isLoading: boolean
  children: React.ReactNode
}

function TripItemCarousel({ isLoading, children }: Props) {
  return (
    <CardCarousel
      width={rem(280)}
      gutterSize={16}
      desktopGutterSize={20}
      gutterStyle="overflow"
      snap="start"
      pageSize="auto"
    >
      {isLoading &&
        fillArray(4).map((_, index) => <SkeletonTile key={index} />)}
      {!isLoading && children}
    </CardCarousel>
  )
}

export default TripItemCarousel
