import { pluralizeToString } from 'lib/string/pluralize'

export function extractPriceAndValue({
  recommendation,
  pkg,
}: {
  recommendation?: App.Recommendation
  pkg: App.Package
}): { value: number; price: number; luxPlusPrice?: number } {
  if (recommendation?.checkInDatePrice && recommendation.checkInDateValue) {
    return {
      price: recommendation?.checkInDatePrice,
      value: recommendation.checkInDateValue,
    }
  } else {
    const { price, memberPrice, value, propertyFees = 0 } = pkg
    const maybeAddFees = (amount: number) => amount + propertyFees
    return {
      price: maybeAddFees(price),
      luxPlusPrice: memberPrice ? maybeAddFees(memberPrice) : undefined,
      value: maybeAddFees(value),
    }
  }
}

export function getDurationLabel({
  durationLabel,
  duration,
  recommendation,
}: {
  durationLabel: string
  duration: number
  recommendation?: App.Recommendation
}): string {
  if (recommendation?.checkInDatePrice && recommendation.checkInDateValue) {
    return `${pluralizeToString('night', duration).toLocaleLowerCase()} from`
  }
  return `${durationLabel.toLocaleLowerCase()} from`
}
